import { IExpandedTextAdTemplate } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import DesktopPreview from "../../../AdWords/AdPreview/DesktopPreview";
import ResponsiveAdPreviewFromTemplate from "../../../../hooks/dynamicCampaigns/ResponsiveAdPreviewFromTemplate";

const SmallResponsiveAdPreviewValidation = ({
    vehiclesForPreview,
    processedAd
}: {
    vehiclesForPreview: any;
    processedAd: ResponsiveAdPreviewFromTemplate;
}) => {
    const representativeItems = Object.keys(vehiclesForPreview).map((inventoryKey) => vehiclesForPreview[inventoryKey]);
    const ad = processedAd.getAd();
    if (!representativeItems) {
        return <div>There are no vehicles to preview</div>;
    }

    const areThereVehiclesThatPassAdLevelConditions = representativeItems.filter((itemGroup) =>
        processedAd.doAnyConditionsPassForPartAndItem(ad, itemGroup[0])
    );

    let isValid = processedAd.isValid();
    let doVehiclesPassAdConditions = areThereVehiclesThatPassAdLevelConditions.length > 0;
    if (isValid.valid) {
        // If the ad is valid, we need to make sure we have inventory for this ad.
        isValid = {
            valid: doVehiclesPassAdConditions,
            message: doVehiclesPassAdConditions ? "" : "All vehicles fail Ad level conditions."
        };
    }

    return (
        <div
            className={
                (isValid.valid ? "border border-green-400" : "border-2 border-red-500") +
                " rounded  flex flex-col bg-white"
            }
        >
            <div className={"px-4 py-2"}>{ad.label}</div>
            <div
                className={
                    "min-h-64 overflow-y-scroll rounded-b flex flex-col border-t divide-y divide-gray-400 gap-2 p-2"
                }
                style={{ maxHeight: isValid.valid ? "20.7rem" : "18rem" }}
            >
                {representativeItems.map((items, index) => {
                    const doesVehiclePassAdConditions = processedAd.doAnyConditionsPassForPartAndItem(ad, items[0]);
                    return (
                        <div key={"rsa_" + index} className={" bg-light-gray p-2 rounded-lg"}>
                            <div className={"-mt-4"}>
                                <DesktopPreview {...processedAd.render(items)} />
                            </div>
                            {!doesVehiclePassAdConditions && (
                                <div className={"text-red-500 text-sm px-2 pt-1"}>
                                    The conditions do {doesVehiclePassAdConditions ? "" : "not "}pass for {ad.label} for
                                    Ad Group {items[0].Make} {items[0].Model} {items[0].Year}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            {!isValid.valid && (
                <div className={"px-4 py-2 text-red-500 border-t border-red-500"}>{isValid.message}</div>
            )}
        </div>
    );
};

export default SmallResponsiveAdPreviewValidation;
