import { useCallback, useEffect, useMemo, useState } from "react";
import { Schema } from "normalizr";
import * as api from "../middleware/api";
import IPagedResponse from "../interfaces/IPagedResponse";
import buildUrl from "../utils/UrlUtils";
import { isArray, map, isEmpty } from "lodash";

export type IUseApiHandler = (requestParams?: IRequestParams) => Promise<void | IPagedResponse<any>[]>;

interface IRequestParams {
    [key: string]: any;
}
export interface IUrlParams {
    [key: string]: string | Object;
}

type useApiReturn = {
    data: any;
    arrayData: any[];
    response: any;
    loading: boolean;
    streaming: boolean;
    error: any;
    pagination: IPaginationParams;
    abort: () => void;
    next: IUseApiHandler;
    request: IUseApiHandler;
};

type apiMethods = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
interface IUseApiProps {
    schema?: Schema;
    method?: apiMethods;
    subRoute?: string;
    body?: Object | undefined;
    fetchOnInit?: boolean;
    perPageLimit?: number;
    entityKey?: string;
    entityId?: number | null;
    expand?: any;
    stream?: boolean;
    urlParams?: IUrlParams;
    useBuildUrl?: boolean;
    clearOnStart?: boolean;
    startOn?: boolean;
    initialData?: any;
    onStart?: () => void;
    onFinish?: () => void;
    onFail?: () => void;
    onSuccess?: (response: any) => void;
}
interface IPaginationParams {
    limit: number;
    currentPage: number;
    requestBody?: Object;
}

function useApi(
    endpoint: string,
    {
        schema = {},
        method = "GET",
        expand,
        body,
        fetchOnInit = false,
        perPageLimit = 100,
        entityKey,
        entityId,
        stream = false,
        urlParams = {},
        useBuildUrl = true,
        clearOnStart = true,
        startOn = false,
        initialData = {},
        onStart = () => {},
        onFinish = () => {},
        onFail = () => {},
        onSuccess = () => {}
    }: IUseApiProps
): useApiReturn {
    const POST = method === "POST";
    const PUT = method === "PUT";
    const GET = method === "GET";
    const [data, setData] = useState<any>(initialData);
    const [response, setResponse] = useState<any>();
    const [loading, setLoading] = useState<boolean>(fetchOnInit || startOn);
    const [streaming, setStreaming] = useState<boolean>(false);
    const [error, setError] = useState({});
    const [nextPageUrl, setNextPageUrl] = useState<string>("");
    // Previously this used useState but it was only updating the state on the next render instead of immediately.
    let pagination = {
        limit: perPageLimit,
        currentPage: 1
    };
    const setPagination = (thing: any) => {
        // thing could be a callback or an object.
        if (typeof thing === "function") {
            pagination = thing(pagination);
        } else {
            pagination = thing;
        }
    };
    const arrayData = useMemo(() => map(data, (value) => value) ?? [], [data]);

    const fetch = async (
        url: string = endpoint,
        urlParams: IUrlParams | IUrlParams[] = [],
        requestBody = body,
        urlBuild: boolean = useBuildUrl,
        search?: any
    ) => {
        const { currentPage: page, limit } = pagination;
        !streaming && setLoading(true);
        setResponse(null);
        if (!streaming && clearOnStart) {
            setError({});
            setData({});
        }
        onStart();
        !isArray(urlParams) && (urlParams = [urlParams]);
        const requests = [...urlParams].map((param) => {
            let _url = url;
            Object.keys(param).forEach((name) => {
                const value = param[name];
                _url = _url.replace(`:${name}`, value.toString());
            });
            if (urlBuild) {
                _url = buildUrl(_url, {
                    ...(GET && { page, limit }),
                    search,
                    expand
                });
            }
            return api.callApi(
                _url,
                schema,
                method,
                (body = POST || PUT ? (param?.body as Object) || requestBody : undefined)
            );
        });

        return await Promise.all(requests)
            .then((res: IPagedResponse[]) => {
                setResponse([...res].length === 1 ? res[0] : res);
                GET &&
                    setPagination((prevPagination: any) => ({
                        ...prevPagination,
                        currentPage: res[0].result?.currentPage
                    }));
                setNextPageUrl(nextPageUrl);
                return res;
            })
            .catch((error) => {
                setError((prevError) => ({ ...prevError, error }));
                onFail();
                setLoading(false);
            })
            .finally(() => {
                onFinish();
                setLoading(false);
            });
    };

    useEffect(() => {
        if (!isEmpty(response?.entities)) {
            let data = response.entities;
            data = (!!entityKey && data[entityKey]) || data; //if a key is provided, we return only that key object. Can we use the schema to infer this? mmm...
            data = (!!entityId && data[entityId]) || data; //single id param is provided, so we should destructure the selected record.
            // option not to replace current results but append the new ones.
            setData((prevData: any) => ({ ...(stream ? prevData : {}), ...data }));
            onSuccess(response);
            return;
        }
        if (!isEmpty(response?.result)) {
            let data = response.result;
            if (entityKey) data = data[entityKey];
            setData(data);
            onSuccess(response);
            return;
        }
    }, [response]);

    useEffect(() => {
        if (!!nextPageUrl) {
            setLoading(false);
            if (stream) {
                setStreaming(stream);
                next();
            }
        } else {
            streaming && setStreaming(false);
            loading && !!response && setLoading(false);
        }
    }, [nextPageUrl]);

    useEffect(() => {
        //triggers when startOn flag turns to true.
        startOn && fetch(endpoint, urlParams);
    }, [startOn]);

    useEffect(() => {
        //fetch on init and changes in url.
        !!fetchOnInit && fetch(endpoint, urlParams);
    }, [fetchOnInit, endpoint]);

    const next = useCallback(async () => {
        //callback for triggering next page fetch from components.
        !!nextPageUrl && (await fetch(nextPageUrl, {}, {}, false));
    }, [nextPageUrl]);

    const abort = useCallback(() => {
        api.abortNetworkRequests();
        setNextPageUrl("");
    }, []);

    const request = useCallback(
        //callback for triggering fetch from components.
        async (requestParams: IRequestParams = {}) => {
            let {
                limit, //if limit page and body are not provided, defaults to what's currently set.
                page: currentPage,
                body: requestBody
            } = requestParams;

            if (!limit) {
                limit = pagination.limit;
            }

            if (!currentPage) {
                currentPage = pagination.currentPage;
            }

            if (!requestBody) {
                requestBody = body;
            }
            const search = { ...requestParams.search };
            GET && setPagination({ limit, currentPage });
            requestParams.page && delete requestParams.page;
            requestParams.limit && delete requestParams.limit;
            requestParams.search && delete requestParams.search;
            return await fetch(endpoint, requestParams, requestBody, useBuildUrl, search);
        },
        [endpoint, Object.values(pagination)]
    );

    return { data, request, response, next, loading, streaming, error, arrayData, pagination, abort };
}

export default useApi;
