import Button, { baseStyles, secondaryClasses } from "../../../Shared/Button";
import MetricCard from "../../../Shared/MetricCard";
import ModalToggle from "../../../Shared/Dialogs/ModalToggle";
import { callApiWithoutNormalizr } from "../../../../middleware/api";
import { fetchDealerSetupData } from "../../../../actions/DealerSetupActions";
import React, { useEffect, useState, useRef } from "react";
import { IConditional, IDynamicCampaign } from "../../../../interfaces/DynamicCampaigns/IDynamicCampaign";
import IAppState from "../../../../interfaces/IAppState";
import { connect } from "react-redux";
import { IDealerSetupState } from "../../../../reducers/dealerSetup";
import IDynamicCampaignFormValue from "../../../../interfaces/DynamicCampaigns/IDynamicCampaignFormValue";
import Mustache from "mustache";
import moment from "moment";
import ITemplatePart from "../../../../interfaces/DynamicCampaigns/ITemplatePart";
import SmallResponsiveAdPreviewValidation from "./SmallResponsiveAdPreviewValidation";
import { Link } from "react-router-dom";
import InventoryGroupFilter from "../InventoryGroupFilter";
import Label from "../../../Shared/Form/Label";
import InventoryFilters from "../InventoryFilters";
import CollapsibleSection from "../../../Shared/CollapsibleSection";
import InventoryFilter from "../InventoryFilter";
import ResponsiveAdPreviewFromTemplate from "../../../../hooks/dynamicCampaigns/ResponsiveAdPreviewFromTemplate";
import adGroups from "../../../../reducers/adWords/adGroups";

function previewPossibleValidDynamicCampaignAdsCount(
    dynamicCampaign: IDynamicCampaignFormValue,
    inventory: any
): [number[], any[]] {
    let actualAdCount = [] as number[];
    const rsasToPush = dynamicCampaign.expandedTextAdTemplates.filter((template) => {
        const conditionals = template.conditionals as {
            value: string;
            field: string;
            comparator: string;
            id: number;
        }[];

        const parsedAdTemplate = new ResponsiveAdPreviewFromTemplate(template);

        if (conditionals.length === 0) {
            actualAdCount.push(Object.keys(inventory).length);
            return true;
        }

        const adsToPush = Object.keys(inventory).filter((key) => {
            const representativeItem = inventory[key][0];
            if (!representativeItem) {
                return false;
            }

            return parsedAdTemplate.doAnyConditionsPassForPartAndItem(template, representativeItem);
        });

        actualAdCount.push(adsToPush.length);
        return adsToPush.length > 0;
    });

    return [actualAdCount, rsasToPush];
}

const DynamicCampaignPushSummary = function ({
    dynamicCampaign,
    dealerSetup,
    canCampaignPush,
    pushDynamicCampaign,
    fetchDealerSetupData,
    loading
}: {
    dynamicCampaign: IDynamicCampaignFormValue;
    dealerSetup: IDealerSetupState;
    canCampaignPush: boolean;
    pushDynamicCampaign: () => void;
    fetchDealerSetupData(clientId: number): void;
    loading: boolean;
}) {
    const [vehiclesForPreview, setPreviewVehicles] = useState({});
    const [showPushPreviewModal, setPushInventoryModal] = useState(false);
    const keywords = dynamicCampaign.keywordTemplateRelationships.reduce(
        (carry, k) =>
            carry.concat(
                k.keywordTemplate.keywords.reduce((kwCarry: string[], kw) => kwCarry.concat(kw.split("\n")), [])
            ),
        [] as string[]
    );
    const keywordVariables = keywords.reduce((carry, keyword) => {
        return [
            ...new Set([
                ...carry,
                ...Mustache.parse(keyword)
                    .filter(([type]) => {
                        return type === "name";
                    })
                    .map(([type, name]) => name)
            ])
        ];
    }, [] as string[]);
    const dealerSetupVariations = keywordVariables.includes("Location")
        ? Math.max((dealerSetup?.formValues?.locations?.fields?.locations?.value?.ad_groups ?? []).length, 1)
        : 1;
    const keywordsPerAdGroup = keywords.length * dealerSetupVariations;
    const vehiclesForPreviewCount = Object.keys(vehiclesForPreview).length;

    const [adCounts, previewOfAdsToPush] = previewPossibleValidDynamicCampaignAdsCount(
        dynamicCampaign,
        vehiclesForPreview
    );

    const actualAdCount = adCounts.reduce((carry: number, count: number) => carry + count, 0);
    const adsToVisuallyPreview = dynamicCampaign.expandedTextAdTemplates.map(
        (ad) => new ResponsiveAdPreviewFromTemplate(ad)
    );
    // We want the smallest number because if there are 0 vehicles, we will push 0 ads.
    // If there are 0 possible ads, we will push 0 ads, even with 100 vehicles.
    const canPush =
        actualAdCount > 0 &&
        keywordsPerAdGroup <= 20000 &&
        vehiclesForPreviewCount > 0 &&
        adsToVisuallyPreview.filter((ad) => !ad.isValid().valid).length === 0;

    useEffect(() => {
        if (!dynamicCampaign?.clientId) {
            return;
        }
        callApiWithoutNormalizr(
            `/clients/${dynamicCampaign.clientId}/dynamic-campaigns/${dynamicCampaign.id}/inventory`
        ).then((data: any[]) => {
            const reduceVehiclesByDynamicCampaignKey = (items: any, item: any) => {
                const keyForItem = JSON.stringify(
                    dynamicCampaign.inventoryGroupFields.reduce((keys, key) => ({ ...keys, [key]: item[key] }), {})
                );

                return {
                    ...items,
                    [keyForItem]: [...(items[keyForItem] ? items[keyForItem] : []), item]
                };
            };

            const vehiclesReducedByKey = data.reduce(reduceVehiclesByDynamicCampaignKey, {});
            const valid = Object.keys(vehiclesReducedByKey).reduce(
                (items: any, groupKey: string) => ({
                    ...items,
                    [groupKey]: vehiclesReducedByKey[groupKey]
                }),
                {}
            );

            setPreviewVehicles(valid);
        });
        fetchDealerSetupData(dynamicCampaign.clientId);
    }, [dynamicCampaign?.id, canPush]);

    function pushButtonText() {
        if (canPush) {
            return "Push";
        }

        if (vehiclesForPreviewCount === 0) {
            return "No Inventory to Push";
        }

        if (keywordsPerAdGroup > 20000) {
            return "Too many Keywords per AdGroup";
        }

        if (vehiclesForPreviewCount === 0) {
            return "No Vehicles to Push";
        }

        if (actualAdCount === 0) {
            return "No Ads to Push";
        }

        if (previewOfAdsToPush.filter((ad) => !new ResponsiveAdPreviewFromTemplate(ad).isValid().valid).length > 0) {
            return "There are non-valid Ads";
        }

        return "Cannot Push";
    }

    return (
        <ModalToggle
            toggleButton={(open: () => void) => (
                <Button
                    type="button"
                    disabled={!canCampaignPush}
                    onClick={() => {
                        setPushInventoryModal(!showPushPreviewModal);
                        open();
                    }}
                    styles={`bg-green-500 text-white ${canCampaignPush ? "hover:bg-green-600" : "opacity-50"} mx-4`}
                >
                    Push
                </Button>
            )}
            modalContent={(hide: any) => (
                <div className="flex flex-col gap-4 bg-light-gray rounded p-4 -m-4">
                    <div className="flex flex-col gap-0">
                        <div className={"text-3xl"}>Ad Push Preview</div>
                        <div className="-mt-2 text-sm text-gray-600">
                            Sometimes unexpected errors may occur or Google's validation might change, impacting the
                            actual push.
                        </div>
                    </div>

                    <pre className="text-base">{dynamicCampaign.name}</pre>

                    <div className={"grid grid-cols-3 items-center justify-center gap-4"}>
                        <MetricCard
                            value={keywordsPerAdGroup}
                            label={"Keywords Per AdGroup"}
                            subLabel={"keywords"}
                            information={"Maximum of 20,000 per AdGroup"}
                            classes={keywordsPerAdGroup > 20000 ? "bg-red-100 border border-red-500" : "bg-white"}
                        />
                        <MetricCard
                            value={adCounts.filter((count) => count > 0).length}
                            label={"Ads per AdGroup"}
                            subLabel={"Likely to push"}
                            information={"Maximum of 3 per AdGroup"}
                        />
                        <MetricCard
                            value={dealerSetupVariations}
                            label={"Location Variations"}
                            subLabel={"cities"}
                            information={"From Dealer Setup's Locations"}
                            classes="bg-white"
                        />
                        <MetricCard
                            value={previewOfAdsToPush.length * keywordsPerAdGroup}
                            label={"Total Keywords"}
                            subLabel={""}
                            information={"Maximum of 5,000,000 per Account"}
                            classes="bg-white"
                        />
                        <MetricCard
                            value={vehiclesForPreviewCount}
                            label={"Total Ad Groups"}
                            subLabel={"ad groups"}
                            information={"Maximum of 20,000 per Campaign"}
                            classes={vehiclesForPreview > 20000 ? "bg-red-100 border border-red-500" : "bg-white"}
                        />
                        <MetricCard
                            value={actualAdCount}
                            label={"Total Ads"}
                            subLabel={"from all ad groups"}
                            classes="bg-white"
                        />
                    </div>

                    {actualAdCount > 0 && (
                        <div>
                            <div className="mt-2 text-lg py-2">Responsive Search Ad Preview</div>

                            <div className="grid grid-cols-2 gap-2">
                                {adsToVisuallyPreview.map((ad, key) => (
                                    <SmallResponsiveAdPreviewValidation
                                        key={"small-rsa-preview-" + key}
                                        vehiclesForPreview={vehiclesForPreview}
                                        processedAd={ad}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    {vehiclesForPreviewCount === 0 && (
                        <div>
                            <CollapsibleSection
                                title={"Filters used to include inventory"}
                                subTitle={
                                    dynamicCampaign?.inventoryFilters?.length > 0
                                        ? dynamicCampaign.inventoryFilters
                                              .map(
                                                  (inventoryFilter: any) =>
                                                      (inventoryFilter.field ?? "") +
                                                      " " +
                                                      (inventoryFilter.comparator ?? "") +
                                                      " " +
                                                      (inventoryFilter.value ?? "")
                                              )
                                              .join(" AND ")
                                        : "No filters"
                                }
                            >
                                <div className="text-gray-600">
                                    These filters are used to{" "}
                                    <b className="font-bold">
                                        <i className="italic">include</i>
                                    </b>{" "}
                                    inventory in the campaign. These can be edited in the{" "}
                                    <b className="font-bold">Inventory conditional logic</b> section.
                                </div>

                                {dynamicCampaign.inventoryFilters.map((inventoryFilter: any, index: number) => (
                                    <InventoryFilter
                                        index={index}
                                        condition={inventoryFilter}
                                        key={inventoryFilter.id}
                                        onChange={(item: IConditional) => {}}
                                        setFieldTouched={() => {}}
                                        names={{
                                            comparator: `inventoryFilters[${index}].comparator`,
                                            field: `inventoryFilters[${index}].field`,
                                            value: `inventoryFilters[${index}].value`
                                        }}
                                        errors={{}}
                                        autoFocus={false}
                                    />
                                ))}
                            </CollapsibleSection>

                            <CollapsibleSection
                                title={"Filters used to exclude inventory"}
                                subTitle={
                                    (dynamicCampaign.inventoryGroupFilterField ?? "") +
                                    " " +
                                    (dynamicCampaign.inventoryGroupFilterComparator ?? "") +
                                    " " +
                                    (dynamicCampaign.inventoryGroupFilterValue ?? "")
                                }
                            >
                                <div className="text-gray-600">
                                    These filters are used to{" "}
                                    <b className="font-bold">
                                        <i className="italic">exclude</i>
                                    </b>{" "}
                                    inventory in the campaign. These can be edited in the{" "}
                                    <b className="font-bold">Ad Groups</b> section.
                                </div>
                                <InventoryGroupFilter
                                    comparator={dynamicCampaign.inventoryGroupFilterComparator}
                                    field={dynamicCampaign.inventoryGroupFilterField}
                                    value={dynamicCampaign.inventoryGroupFilterValue}
                                    errors={{}}
                                    setFieldTouched={() => {}}
                                    autoFocus={false}
                                />
                            </CollapsibleSection>
                        </div>
                    )}

                    {!dynamicCampaign.adwordsCampaignId && !dynamicCampaign.microsoftCampaignId && (
                        <div className="py-2 px-4 border border-red-500 bg-red-100 text-red-700 text-sm">
                            This campaign is not attached to ANY Google or Microsoft Ads campaign yet.
                            <strong>
                                Please attached it to a Google or Microsoft campaign before attempting to push.
                            </strong>
                        </div>
                    )}

                    {vehiclesForPreviewCount === 0 && (
                        <div className="my-4 p-4 bg-red-100 border border-red-600 text-red-700 rounded-lg">
                            <div className="text-2xl font-font-bold">No available inventory to push.</div>
                            <div className="text-sm  tracking-wide">
                                Please review the inventory conditions and adgroup filters above as they may be
                                excluding more inventory than you expect
                            </div>
                        </div>
                    )}

                    <div className="flex gap-4 underline">
                        <a
                            target="_blank"
                            href="https://support.google.com/google-ads/answer/6372658?hl=en"
                            className="text-sm"
                        >
                            View Google's account limits
                        </a>
                        <a
                            target="_blank"
                            href="https://learn.microsoft.com/en-us/advertising/guides/entity-hierarchy-limits"
                            className="text-sm"
                        >
                            View Microsoft's account limits
                        </a>
                    </div>

                    <div className="flex justify-end gap-4">
                        <Button
                            type="button"
                            disabled={false}
                            onClick={hide}
                            styles={`${baseStyles} ${secondaryClasses} ml-4`}
                        >
                            Close
                        </Button>

                        <Button
                            type="button"
                            disabled={!canPush}
                            onClick={() => pushDynamicCampaign()}
                            styles={`bg-green-500 text-white ${canCampaignPush ? "hover:bg-green-600" : "opacity-50"}`}
                        >
                            {pushButtonText()}
                        </Button>
                    </div>
                </div>
            )}
        />
    );
};
const mapStateToProps = (state: IAppState) => ({
    dealerSetup: state.dealerSetup
});
const mapDispatchToProps = (dispatch: any) => ({
    fetchDealerSetupData(clientId: number) {
        dispatch(fetchDealerSetupData(clientId));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DynamicCampaignPushSummary);
